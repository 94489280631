<template>
  <div>
    <el-row type="flex" justify="space-between">
      <div class="target-text"><b>Min</b>: {{ scale.target_a }}</div>
      <div class="card-view-vertical-divider"></div>
      <div class="target-text"><b>Target</b>: {{ scale.target_b }}</div>
      <div class="card-view-vertical-divider"></div>
      <div class="target-text"><b>Max</b>: {{ scale.target_c }}</div>
    </el-row>
    <el-divider class="card-view-divider"></el-divider>
    <el-row type="flex" justify="space-between">
      <div>Выплаты:</div>
      <div><b>Min</b>: {{ scale.pay_a }}</div>
      <div><b>Target</b>: {{ scale.pay_b }}</div>
      <div><b>Max</b>: {{ scale.pay_c }}</div>
    </el-row>
  </div>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "card-view",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  data() {
    return {
      scale: {
        target_a: null,
        target_b: null,
        target_c: null,
        pay_a: null,
        pay_b: null,
        pay_c: null,
      },
    }
  },
  mounted() {

  },
  computed: {},
  methods: {}
}
</script>

<style>
.target-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: justify;
  flex-grow: 1;
  flex-basis: 0;
}

.card-view-vertical-divider {
  width: 1px;
  margin: 6px 5px;
  background: #DCDFE6;
}
</style>